<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M11.0705 0C9.88953 4.16324e-08 8.70849 0.458659 7.80966 1.37582L1.34855 7.96869C-0.449517 9.80302 -0.449517 12.7882 1.34855 14.6234C2.24738 15.5409 3.42863 16 4.60939 16C5.79096 16 6.97139 15.5409 7.87022 14.6234L9.01853 13.4525C8.78801 12.9312 8.65595 12.3548 8.65595 11.7465C8.65595 10.5862 9.12439 9.53583 9.87757 8.7802L6.08123 4.90563L8.67507 2.25888C9.99619 0.911658 12.144 0.911658 13.4651 2.25888C14.1049 2.91168 14.458 3.77975 14.458 4.70316C14.458 5.62656 14.1053 6.49464 13.4651 7.14743L13.0914 7.5296C13.0702 7.55125 13.0543 7.57541 13.0372 7.59872C13.5207 7.63494 13.9808 7.7564 14.4031 7.94999C15.2268 7.07072 15.682 5.92382 15.682 4.70316C15.682 3.44586 15.202 2.26467 14.3305 1.37582C13.4317 0.458659 12.2515 0 11.0705 0ZM12.736 8.41592C10.9363 8.41592 9.47195 9.9101 9.47195 11.7465C9.47195 13.5829 10.9363 15.0771 12.736 15.0771C14.5357 15.0771 16 13.5829 16 11.7465C16 9.9101 14.5357 8.41592 12.736 8.41592ZM13.756 10.0812C13.9126 10.0812 14.0691 10.1422 14.1887 10.2642C14.4278 10.5081 14.4278 10.9033 14.1887 11.1472L12.1487 13.2288C12.0291 13.3512 11.8726 13.4118 11.716 13.4118C11.5593 13.4118 11.4028 13.3508 11.2833 13.2288C11.0446 12.9849 11.0442 12.5898 11.2833 12.3458L13.3233 10.2642C13.4428 10.1422 13.5994 10.0812 13.756 10.0812Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
